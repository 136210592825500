import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ["values", "set", "name", "description"]

  initialize() {
    this.labValues = new Map()
    this.isNewSet = false
    
    // Listen for modal show event
    document.getElementById('labsModal').addEventListener('show.bs.modal', (event) => {
      // Check if this is triggered by the Add Labs button
      if (event.relatedTarget?.hasAttribute('data-bs-toggle')) {
        this.isNewSet = true
        this.currentEditingSet = null
        this.currentSetIndex = this.getNextSetIndex()
        
        // Clear the labValues map
        this.labValues = new Map()
        
        // Clear all modal inputs
        const modalInputs = document.querySelectorAll('#labsModal input[type="number"]')
        modalInputs.forEach(input => {
          input.value = ''
        })
      }
    })
  }

  editSet(event) {
    // Don't trigger if clicking the remove button
    if (event.target.closest('[data-action="click->lab-set#removeSet"]')) {
      return
    }

    this.isNewSet = false
    // Find the parent card element from the edit button
    this.currentEditingSet = event.target.closest('[data-lab-set-target="set"]')
    
    // Get the lab set index from the hidden id field
    const idField = this.currentEditingSet.querySelector('input[name*="[scenario_lab_sets_attributes]"][name*="[id]"]')
    this.currentSetIndex = idField ? idField.name.match(/scenario_lab_sets_attributes\]\[(\d+)\]/)[1] : '0'
    
    // Reset the labValues map for the new edit session
    this.labValues = new Map()

    // Store all existing values from the current set
    const existingValues = this.currentEditingSet.querySelectorAll('[data-param-id]')
    existingValues.forEach(valueDiv => {
      const paramId = valueDiv.dataset.paramId
      const value = valueDiv.dataset.paramValue
      const formFields = valueDiv.querySelectorAll('input')
      const fieldData = {}
      formFields.forEach(field => {
        fieldData[field.name.match(/\[(\w+)\]$/)[1]] = field.value
        const nestedMatch = field.name.match(/lab_parameter_scenario_lab_sets_attributes\]\[(\d+)\]/)
        if (nestedMatch) {
          fieldData.nestedIndex = nestedMatch[1]
        }
      })
      
      this.labValues.set(paramId, {
        value,
        name: valueDiv.querySelector('label')?.textContent.trim(),
        id: fieldData.id,
        nestedIndex: fieldData.nestedIndex,
        existingFields: fieldData
      })
    })

    // Populate modal inputs
    const modalInputs = document.querySelectorAll('#labsModal input[type="number"]')
    modalInputs.forEach(input => {
      const paramId = input.dataset.paramId
      const existingData = this.labValues.get(paramId)
      input.value = existingData ? existingData.value : ''
    })

    // Get and set name and description
    const nameField = this.currentEditingSet.querySelector('input[name$="[name]"]')
    const descriptionField = this.currentEditingSet.querySelector('input[name$="[description]"]')
    
    if (this.hasNameTarget && nameField) {
      this.nameTarget.value = nameField.value
    }
    if (this.hasDescriptionTarget && descriptionField) {
      this.descriptionTarget.value = descriptionField.value
    }

    // Open modal using Bootstrap 5's Modal
    const modalElement = document.getElementById('labsModal')
    const modal = new Modal(modalElement)
    modal.show()
  }

  updateValue(event) {
    const input = event.target
    const paramId = input.dataset.paramId
    const paramName = input.dataset.paramName
    const grouping = input.closest('.card').querySelector('.card-header h5').textContent.trim()
    const value = input.value

    if (value) {
      this.labValues.set(paramId, { 
        value, 
        name: paramName,
        grouping: grouping
      })
    } else {
      this.labValues.delete(paramId)
    }
  }

  saveChanges(event) {
    event.preventDefault()
    
    if (this.isNewSet) {
      this.createNewSet()
    } else if (this.currentEditingSet) {
      this.updateExistingSet()
    }

    // Close modal using Bootstrap 5's Modal
    const modalElement = document.getElementById('labsModal')
    const modal = Modal.getInstance(modalElement)
    modal.hide()
    
    // Reset state
    this.isNewSet = false
    this.currentEditingSet = null
  }

  createNewSet() {
    const name = this.hasNameTarget ? this.nameTarget.value : ''
    const description = this.hasDescriptionTarget ? this.descriptionTarget.value : ''

    // Create new set container
    const newSet = document.createElement('div')
    newSet.className = 'vs-card-full mb-3'
    newSet.dataset.labSetTarget = 'set'

    // Create the header and body structure
    newSet.innerHTML = `
      <div class="vs-card-header">
        Lab Set
        <input type="hidden" name="scenario[scenario_lab_sets_attributes][${this.currentSetIndex}][id]" value="">
        <input type="hidden" name="scenario[scenario_lab_sets_attributes][${this.currentSetIndex}][_destroy]" value="false">
        <button type="button" class="btn btn-sm btn-outline-danger float-end ms-2" data-action="click->lab-set#removeSet">
          Remove
        </button>
        <button type="button" class="btn btn-sm btn-outline-primary float-end" data-action="click->lab-set#editSet">
          Edit
        </button>
      </div>
      <div class="vs-card-body">
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-group string">
              <label class="form-label" for="scenario_scenario_lab_sets_attributes_${this.currentSetIndex}_name">Name</label>
              <input class="form-control string" type="text" 
                     name="scenario[scenario_lab_sets_attributes][${this.currentSetIndex}][name]" 
                     id="scenario_scenario_lab_sets_attributes_${this.currentSetIndex}_name"
                     value="${name}">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group string">
              <label class="form-label" for="scenario_scenario_lab_sets_attributes_${this.currentSetIndex}_description">Description</label>
              <input class="form-control string" type="text" 
                     name="scenario[scenario_lab_sets_attributes][${this.currentSetIndex}][description]" 
                     id="scenario_scenario_lab_sets_attributes_${this.currentSetIndex}_description"
                     value="${description}">
            </div>
          </div>
        </div>
        <ul class="list-group" data-lab-set-target="values">
        </ul>
      </div>
    `

    // Group parameters by their grouping
    const groupedParams = new Map()
    this.labValues.forEach((data, paramId) => {
      const grouping = data.grouping || 'Other'
      if (!groupedParams.has(grouping)) {
        groupedParams.set(grouping, [])
      }
      groupedParams.get(grouping).push({ paramId, ...data })
    })
    console.log("GROUPED PARAMS", groupedParams)

    // Add parameter values grouped by their categories
    const valuesContainer = newSet.querySelector('[data-lab-set-target="values"]')
    groupedParams.forEach((params, grouping) => {
      if (grouping !== 'Other') {
        const groupHeader = document.createElement('li')
        groupHeader.className = 'lab-group-item lab-groupings'
        groupHeader.innerHTML = `<div class="mb-0 p-1">${grouping}</div>`
        valuesContainer.appendChild(groupHeader)
      }
      
      params.forEach(data => {
        const paramDiv = this.createParameterDiv(data.paramId, data)
        valuesContainer.appendChild(paramDiv)
      })
    })

    // Add the new set to the container
    const setsContainer = document.getElementById('lab-sets-container')
    setsContainer.appendChild(newSet)
  }

  updateExistingSet() {
    if (!this.currentEditingSet) return

    // Update name and description if they exist
    const nameField = this.currentEditingSet.querySelector('input[name$="[name]"]')
    const descriptionField = this.currentEditingSet.querySelector('input[name$="[description]"]')
    
    if (nameField && this.hasNameTarget) {
      nameField.value = this.nameTarget.value
    }
    if (descriptionField && this.hasDescriptionTarget) {
      descriptionField.value = this.descriptionTarget.value
    }

    // Get the values container for the current set
    const valuesContainer = this.currentEditingSet.querySelector('[data-lab-set-target="values"]')
    if (!valuesContainer) return // Exit if we can't find the values container
    
    // Collect all new values from the modal
    const modalInputs = document.querySelectorAll('#labsModal input[type="number"]')
    modalInputs.forEach(input => {
      const paramId = input.dataset.paramId
      if (input.value) {
        const existingData = this.labValues.get(paramId) || {}
        this.labValues.set(paramId, {
          ...existingData,
          value: input.value,
          name: input.closest('.form-group').querySelector('label').textContent.trim()
        })
      }
    })

    // Update the current set's values
    this.labValues.forEach((data, paramId) => {
      let paramDiv = valuesContainer.querySelector(`[data-param-id="${paramId}"]`)
      
      if (!paramDiv && data.value) {
        paramDiv = this.createParameterDiv(paramId, data)
        valuesContainer.appendChild(paramDiv)
      } else if (paramDiv && data.value) {
        const valueInput = paramDiv.querySelector('input[id$="_value"]')
        if (valueInput) {
          valueInput.value = data.value
          paramDiv.dataset.paramValue = data.value
        }
      }
    })
  }

  getNextSetIndex() {
    const existingSets = document.querySelectorAll('input[name*="[scenario_lab_sets_attributes]"][name*="[id]"]')
    return existingSets.length
  }

  removeSet(event) {
    event.stopPropagation() // Prevent editSet from triggering
    const card = event.target.closest('.vs-card-full')
    const destroyInput = card.querySelector('input[name*="_destroy"]')
    destroyInput.value = '1'
    card.style.display = 'none'
  }

  buildLabValuesHTML(setId) {
    let html = ''
    this.labValues.forEach((data, paramId) => {
      const uniqueKey = data.id || `${new Date().getTime()}_${paramId}`
      html += `
        <div class="col-md-3 mb-2" data-param-id="${paramId}" data-param-value="${data.value}">
          <div class="form-group">
            <label class="form-label">${data.name}</label>
            <input type="hidden" 
                   name="scenario[scenario_lab_sets_attributes][${setId}][lab_parameter_scenario_lab_sets_attributes][${uniqueKey}][lab_parameter_id]" 
                   value="${paramId}">
            ${data.id ? `<input type="hidden" 
                   name="scenario[scenario_lab_sets_attributes][${setId}][lab_parameter_scenario_lab_sets_attributes][${uniqueKey}][id]" 
                   value="${data.id}">` : ''}
            <input type="text" 
                   class="form-control" 
                   name="scenario[scenario_lab_sets_attributes][${setId}][lab_parameter_scenario_lab_sets_attributes][${uniqueKey}][value]" 
                   value="${data.value}" 
                   readonly>
          </div>
        </div>
      `
    })
    return html
  }

  createParameterDiv(paramId, data) {
    const li = document.createElement('li')
    li.className = 'list-group-item'
    li.dataset.paramId = paramId
    li.dataset.paramValue = data.value

    const nestedIndex = data.nestedIndex || `new_${new Date().getTime()}`

    li.innerHTML = `
      <input type="hidden" 
             name="scenario[scenario_lab_sets_attributes][${this.currentSetIndex}][lab_parameter_scenario_lab_sets_attributes][${nestedIndex}][id]" 
             value="${data.id || ''}">
      <input type="hidden" 
             name="scenario[scenario_lab_sets_attributes][${this.currentSetIndex}][lab_parameter_scenario_lab_sets_attributes][${nestedIndex}][lab_parameter_id]" 
             value="${paramId}">
      <input type="hidden" 
             name="scenario[scenario_lab_sets_attributes][${this.currentSetIndex}][lab_parameter_scenario_lab_sets_attributes][${nestedIndex}][value]" 
             value="${data.value}">
      <div class="row">
        <div class="col-md-8 text-start">
          ${data.name}
        </div>
        <div class="col-md-4 text-end">
          ${data.value}
          ${data.unit || ''}
        </div>
      </div>
    `

    return li
  }
} 