import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "selector"]

  toggle(event) {
    event.preventDefault()
    this.formTarget.style.display = this.formTarget.style.display === "none" ? "flex" : "none"
    this.selectorTargets.forEach(target => {
      target.style.display = target.style.display === "none" ? "block" : "none"
    })
  }
} 