import { Controller } from "stimulus";

export default class extends Controller {
   static targets = ["ecgWaveformId", "rhythmName", "rhythmNameLabel"];
    connect() {
       console.log("waveform picker connected");
    }

    selectWaveform(event) {
        let card = event.target.closest(".waveform-card");
        console.log("waveform selected", card);
        this.ecgWaveformIdTarget.value = card.dataset.waveformId;
        this.rhythmNameTarget.value = card.dataset.waveformName;
        this.rhythmNameLabelTarget.textContent = card.dataset.waveformName;
    }
}