import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["systolic", "diastolic", "result"]

  connect() {
    this.calculateMAP()
  }

  calculateMAP() {
    const systolic = parseFloat(this.systolicTarget.value) || 0
    const diastolic = parseFloat(this.diastolicTarget.value) || 0
    const map = ((2 * diastolic) + systolic) / 3
    
    if (systolic && diastolic) {
      this.resultTarget.textContent = `MAP: ${Math.round(map)} mmHg`
    } else {
      this.resultTarget.textContent = ''
    }
  }
} 