import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field"]

  copyPrevious(event) {
    console.log("copyPrevious called")
    event.preventDefault()
    const currentFields = this.fieldTarget
    
    // Debug the DOM structure
    const currentVitalSignDiv = currentFields.closest('[data-controller="vital-signs-copier"]')
    const parentDiv = currentVitalSignDiv.parentElement
    const grandParentDiv = parentDiv.parentElement
    
    // List all vital-signs-copier elements in the grandparent
    const allVitalSigns = grandParentDiv.querySelectorAll('[data-controller="vital-signs-copier"]')
    
    // Try to find the previous one
    let previousVitalSignDiv = null
    Array.from(allVitalSigns).forEach((el, index) => {
      if (el === currentVitalSignDiv && index > 0) {
        previousVitalSignDiv = allVitalSigns[index - 1]
      }
    })
    
    const previousFields = previousVitalSignDiv ? previousVitalSignDiv.querySelector('.nested-fields') : null
    
    if (!previousFields || !previousFields.classList.contains('nested-fields')) {
      console.log("No previous fields found")
      return // No previous vital signs to copy from
    }

    // Copy all input values
    const inputs = currentFields.querySelectorAll('input[type="text"], input[type="number"], input[type="hidden"]')
    inputs.forEach(input => {
      // Extract the field name without the index
      const fieldMatch = input.name.match(/\[([^\]]+)\](?!.*\[)/)
      if (fieldMatch && fieldMatch[1]) {
        const fieldName = fieldMatch[1]
        // Find the corresponding input in previous fields by field name
        const previousInput = previousFields.querySelector(`input[name*="[${fieldName}]"]`)
        if (previousInput && !fieldName.includes('_destroy')) {
          input.value = previousInput.value
        }
      }
    })

    // Copy radio button selections
    const radioGroups = currentFields.querySelectorAll('input[type="radio"]')
    radioGroups.forEach(radio => {
      const fieldMatch = radio.name.match(/\[([^\]]+)\](?!.*\[)/)
      if (fieldMatch && fieldMatch[1]) {
        const fieldName = fieldMatch[1]
        const previousChecked = previousFields.querySelector(`input[type="radio"][name*="[${fieldName}]"]:checked`)
        if (previousChecked) {
          const currentRadio = currentFields.querySelector(`input[type="radio"][name*="[${fieldName}]"][value="${previousChecked.value}"]`)
          if (currentRadio) {
            currentRadio.checked = true
          }
        }
      }
    })

    // Update any displays (like rhythm name label)
    const rhythmLabel = currentFields.querySelector('[data-target="waveform-picker.rhythmNameLabel"]')
    const previousRhythmLabel = previousFields.querySelector('[data-target="waveform-picker.rhythmNameLabel"]')
    if (rhythmLabel && previousRhythmLabel) {
      rhythmLabel.textContent = previousRhythmLabel.textContent
    }

    // Trigger GCS calculation if present
    const gcsCalculator = currentFields.querySelector('[data-controller="gcs-calculator"]')
    if (gcsCalculator) {
      const event = new Event('input')
      gcsCalculator.querySelector('input').dispatchEvent(event)
    }
  }
} 