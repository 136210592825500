import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["eyes", "verbal", "motor", "total"]

  connect() {
    this.calculateTotal()
  }

  calculateTotal() {
    const eyes = parseInt(this.eyesTarget.value) || 0
    const verbal = parseInt(this.verbalTarget.value) || 0
    const motor = parseInt(this.motorTarget.value) || 0
    
    const total = eyes + verbal + motor
    
    if (eyes || verbal || motor) {
      this.totalTarget.value = total
    } else {
      this.totalTarget.value = ''
    }
  }
} 