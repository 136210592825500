import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["title", "content", "grid", "loader", "scenarioAttachmentId"]

  connect() {
    this.currentPage = 1
    this.loading = false
    this.hasMore = true

    console.log("Attachment controller connected")
    // Listen for modal show event
    this.element.addEventListener('show.bs.modal', (event) => {
      const button = event.relatedTarget
      const type = button.dataset.attachmentType
      console.log("Modal showing with type:", type)
      this.setupModal(type)
    })
  }

  setupModal(type) {
    let title = "Add Attachment"
    switch(type) {
      case "ecg":
        title = "Add ECG"
        break
      case "xray":
        title = "Add X-Ray"
        break
      case "simulation_picture":
        title = "Add Simulation Picture"
        break
    }
    this.titleTarget.textContent = title
    this.resetAndFetchAttachments(type)
  }

  resetAndFetchAttachments(type) {
    this.currentPage = 1
    this.loading = false
    this.hasMore = true
    this.attachmentType = type
    this.contentTarget.innerHTML = `
      <div class="container-fluid" style="height: 70vh; overflow-y: auto;" data-action="scroll->attachment#onScroll">
        <div class="row row-cols-1 row-cols-md-4 g-4" data-target="attachment.grid"></div>
        <div class="text-center mt-3" data-target="attachment.loader" style="display: none;">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    `
    this.fetchAttachments()
  }

  async fetchAttachments() {
    if (this.loading || !this.hasMore) return

    this.loading = true
    this.loaderTarget.style.display = 'block'

    try {
      const response = await fetch(`/scenario_attachments/get_attachments?type=${this.attachmentType}&page=${this.currentPage}`)
      const data = await response.json()
      
      data.attachments.forEach(attachment => {
        const col = document.createElement('div')
        col.className = 'col'
        col.innerHTML = `
          <div class="card h-100">
            <div class="card-img-wrapper" 
                 style="height: 300px; overflow: hidden; display: flex; align-items: center; background: #f8f9fa; cursor: pointer;" 
                 data-controller="attachment"
                 data-action="click->attachment#showFullImage"
                 data-full-size="${attachment.full_size}"
                 data-content-type="${attachment.content_type}">
              <img src="${attachment.thumbnail}" 
                   class="card-img-top" 
                   alt="${attachment.name}"
                   style="width: 100%; height: auto; object-fit: contain;">
            </div>
            <div class="card-body">
              <h5 class="card-title">${attachment.name}</h5>
              <button class="btn btn-primary btn-sm" 
                      data-action="click->attachment#selectAttachment" 
                      data-attachment-id="${attachment.id}"
                      data-attachment-name="${attachment.name}">
                Select
              </button>
            </div>
          </div>
        `
        this.gridTarget.appendChild(col)
      })

      this.hasMore = data.has_more
      this.currentPage++
      
    } catch (error) {
      console.error('Error fetching attachments:', error)
      const errorDiv = document.createElement('div')
      errorDiv.className = 'alert alert-danger'
      errorDiv.textContent = 'Error loading attachments'
      this.contentTarget.appendChild(errorDiv)
    } finally {
      this.loading = false
      this.loaderTarget.style.display = this.hasMore ? 'block' : 'none'
    }
  }

  onScroll(event) {
    const container = event.currentTarget
    if (container.scrollHeight - container.scrollTop <= container.clientHeight + 100) {
      this.fetchAttachments()
    }
  }

  setupXRayContent() {
    this.contentTarget.innerHTML = `
      <div class="container-fluid">
        <!-- Add your X-Ray-specific content here -->
      </div>
    `
  }

  setupOtherContent() {
    this.contentTarget.innerHTML = `
      <div class="container-fluid">
        <!-- Add your Other attachment content here -->
      </div>
    `
  }

  save(event) {
    // Handle saving the attachment
    // You can add your save logic here
  }

  showFullImage(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const wrapper = event.currentTarget
    const fullSizeUrl = wrapper.dataset.fullSize
    const contentType = wrapper.dataset.contentType
    const imgElement = wrapper.querySelector('img')
    
    const overlay = document.createElement('div')
    overlay.className = 'position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center'
    overlay.style.cssText = `
      background: rgba(0,0,0,0.7); 
      z-index: 9999;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 2rem;
    `

    if (contentType === 'application/pdf') {
      const content = `
        <div class="position-relative bg-white rounded shadow-lg" style="width: 85vw; height: 85vh;">
          <button class="btn btn-outline-dark position-absolute top-0 end-0 m-2" 
                  style="z-index: 10000;"
                  onclick="this.closest('.position-fixed').remove()">
            <i class="fas fa-times"></i> Close
          </button>
          <iframe src="${fullSizeUrl}" 
                  style="width: 100%; height: 100%; border: none; border-radius: 0.375rem;"
                  title="PDF Viewer">
          </iframe>
        </div>
      `
      overlay.innerHTML = content
    } else {
      const content = `
        <div class="position-relative bg-white p-2 rounded shadow-lg" style="max-width: 85vw; max-height: 85vh;">
          <button class="btn btn-outline-dark position-absolute top-0 end-0 m-2" 
                  style="z-index: 10000;"
                  onclick="this.closest('.position-fixed').remove()">
            <i class="fas fa-times"></i> Close
          </button>
          <img src="${fullSizeUrl}" 
               class="img-fluid rounded" 
               style="max-height: 85vh; max-width: 85vw; width: auto; display: block;"
               alt="${imgElement.alt}">
        </div>
      `
      overlay.innerHTML = content
    }
    
    document.body.appendChild(overlay)
    
    overlay.addEventListener('click', (e) => {
      if (e.target === overlay) {
        overlay.remove()
      }
    })
  }

  selectAttachment(event) {
    event.preventDefault()
    const attachmentId = event.currentTarget.dataset.attachmentId
    const attachmentName = event.currentTarget.closest('.card').querySelector('.card-title').textContent
    
    // Get the select field
    const instance = event.target.closest('.nested-fields')
    console.log("instance", instance)
    const inputField = instance.getElementsByClassName("att-id")[0]
    const nameField = instance.getElementsByClassName("att-name")[0]
    if (inputField) {
      inputField.value = attachmentId
      nameField.textContent = attachmentName
      
      // Trigger change event
      inputField.dispatchEvent(new Event('change'))
    }
    
    // Close the modal
    const modal = event.target.closest('.modal')
    if (modal) {
      const closeButton = modal.querySelector('[data-bs-dismiss="modal"]')
      if (closeButton) {
        closeButton.click()
      }
    }
  }
} 